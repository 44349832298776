import DataTable from 'datatables.net-dt';


import DefaultController from "../../defaultController";
import Utils from "../../../utils";
import WmsTransactionEntity from "../transactions/entity";
import eventBus from '../../../utils/eventBus';


export default class WMSPicking extends DefaultController {

    private wmsUIEvents = eventBus.channel('wms_ui')

    private pickData: any = {}
    private datatable: null | DataTable = null

    private currentScanMode = "product";
    private currentLocationId: undefined | string;
    private currentPalletId: undefined | string;
    private currentProductId: undefined | string;
    private currentKeyboardInput = "";

    private infoBar: HTMLElement | undefined;

    async init() {
        this.entity = "wms/transactions";
        this.infoBar = document.querySelector("#wms_picking_action") as HTMLElement;
        await super.init();
        await this.mapData();
        console.log("pickData", this.pickData);
        this.bindKeyboardEvents();
        this.createTable()
        this.continue()
        this.infoBar.innerHTML = `<b>Artikel oder Palette scannen!</b>`
    }

    bindKeyboardEvents() {
        document.addEventListener("keyup", (e) => {
            if (e.key === "Enter") {
                if (this.currentScanMode === "product") {
                    this.scanProductOrPallet(this.currentKeyboardInput)
                } else if (this.currentScanMode === "serial") {
                    this.scanEan(this.currentKeyboardInput);
                }
                this.currentKeyboardInput = "";
            } else if (e.key !== "Meta" && e.key !== "Shift") {
                console.log(e)
                if (e.key === "Backspace") {
                    this.currentKeyboardInput = this.currentKeyboardInput.slice(0, -1);
                } else {
                    this.currentKeyboardInput += e.key;
                }
                if (this.infoBar) {
                    if (this.currentScanMode === "product") {
                        this.infoBar.innerHTML = `<b>Artikel oder Palette scannen!</b> - ${this.currentKeyboardInput} <button class="btn btn-xs btn-danger" id="wms_picking_cancel_action">X</button>`
                    } else if (this.currentScanMode === "serial") {
                        this.infoBar.innerHTML = `<b>Seriennummer scannen!</b> - ${this.currentKeyboardInput} <button class="btn btn-xs btn-danger" id="wms_picking_cancel_action">X</button>`
                    }
                }
            }
        })
    }

    scanProductOrPallet(ean: string) {
        const productElems = document.querySelectorAll(`.datatables-users tr[data-product-ean='${ean}']`) as NodeListOf<HTMLElement>
        const palletElems = document.querySelectorAll(`.datatables-users tr[data-pallet-id='${ean}']`) as NodeListOf<HTMLElement>
        if (productElems.length > 0) {
            productElems.forEach((productElem) => {
                const wmsId = productElem.getAttribute("data-wms-id") as string
                const serial = productElem.getAttribute("data-serial")
                const palletId = productElem.getAttribute("data-pallet-id")
                if ((this.currentPalletId && palletId === this.currentPalletId) || !this.currentPalletId) {
                    if (!serial) {
                        this.pickItemDone(wmsId)
                    } else {
                        this.currentProductId = ean;
                        this.currentScanMode = "serial"
                        if (this.infoBar) {
                            this.infoBar.innerHTML = `<b>Seriennummer scannen!</b> <button class="btn btn-xs btn-danger" id="wms_picking_cancel_action">X</button>`
                        }
                        productElem.style.background = "#e7e7e7"
                    }
                }
            })
        } else if (palletElems.length > 0) {
            palletElems.forEach((palletElem) => {
                this.currentPalletId = ean;
                palletElem.style.background = "#e7e7e7"
            })
        } else {
            this.toastr.error("NOTHIng found")
        }
    }

    scanEan(serial: string) {
        let elem = null;
        if (this.currentPalletId) {
            elem = document.querySelector(`.datatables-users tr[data-product-ean='${this.currentProductId}'][data-pallet-id='${this.currentPalletId}'][data-serial='${serial}']`)
        } else {
            elem = document.querySelector(`.datatables-users tr[data-product-ean='${this.currentProductId}'][data-serial='${serial}']`)
        }
        if (elem) {
            const wmsId = elem.getAttribute("data-wms-id") as string
            this.pickItemDone(wmsId)
        } else {
            this.toastr.error("NOTHIng found")
        }
    }


    pickReset() {

        (document.querySelectorAll(`.datatables-users tr`) as NodeListOf<HTMLElement>).forEach((tr) => {
            tr.style.background = '';
        });
        this.currentScanMode = "product"
        this.currentKeyboardInput = "";
        if (this.infoBar) {
            this.infoBar.innerHTML = `<b>Artikel oder Palette scannen!</b>`
        }
    }

    async pickItemDone(id: string) {
        this.currentPalletId = undefined;
        this.currentProductId = undefined;
        const elem = document.querySelector(`.datatables-users tr[data-wms-id='${id}']`) as HTMLElement;
        elem.remove();
        this.pickReset();
        await Utils.entity.upsert({
            uuid: id,
            picked: true,
            picked_at: new Date().toISOString()
        }, 'wms/transaction_order_items')

        // @ts-ignore
        const currentLocation = this.pickData[this.currentLocationId];
        this.setPicked(currentLocation, id)
        this.continue()

        this.toastr.success(`Done picking ${id}`)
        this.wmsUIEvents.trigger("item_picked")
    }

    setPicked(location: IWMSPickLocation, id: string) {
        console.log("setpicked", id);
        // @ts-ignore
        [Object.values(location.items), ...Object.values(location.pallets).map(p => Object.values(p.items))].flat().forEach((item: IWMSPickListItem) => {
            console.log(item)
            item.maps.forEach((map) => {
                if (map.uuid === id) {
                    map.picked = true
                }
            })
        })
    }

    async generateWarehouseMap() {
        // Retrieve the current warehouse ID from localStorage
        const currentWarehouseId = localStorage.getItem('tickware_wms_warehouse') as string;

        // Get the warehouse information by querying the ERP system and retrieve the warehouse configuration
        const warehouse = (await Utils.entity.get(currentWarehouseId, 'erp/warehouses')).data[0];
        const config = warehouse.wmsConfig; // Store the warehouse configuration (assumed to be a 2D grid)

        // Initialize the warehouseMap array, which will hold the locations found in the path
        let warehouseMap: string[] = [];

        if (!config || config.length === 0) {
            // If the warehouse configuration is empty, return an empty map
            return warehouseMap;
        } else {
            // Variables to track the start, end, current, and past cells
            let startCell: number[] = [];
            let endCell: number[] = [];
            let currentCell: number[] = [];
            let pastCells: number[][] = []; // Store the cells that have already been visited

            // Function to determine neighboring cells based on direction
            const getNeighborCell = (row: number, col: number, direction: string) => {
                switch (direction) {
                    case 'left':
                        return config[row] ? config[row][col - 1] : null;
                    case 'right':
                        return config[row] ? config[row][col + 1] : null;
                    case 'top':
                        return config[row - 1] ? config[row - 1][col] : null;
                    case 'bottom':
                        return config[row + 1] ? config[row + 1][col] : null;
                    default:
                        return null;
                }
            };

            // Function to check if a specific cell has already been visited
            const isVisited = (row: number, col: number) => {
                return pastCells.some(cell => cell[0] === row && cell[1] === col);
            };

            // Function to update the current cell to a neighboring "PATH" cell, if valid
            const updateCurrentCell = (row: number, col: number, direction: string) => {
                const neighbor = getNeighborCell(row, col, direction);
                const [nextRow, nextCol] = direction === 'left' ? [row, col - 1]
                    : direction === 'right' ? [row, col + 1]
                        : direction === 'top' ? [row - 1, col]
                            : [row + 1, col]; // bottom

                // Check if the neighboring cell is a valid "PATH" and hasn't been visited
                if (neighbor && neighbor.mode === "PATH" && !isVisited(nextRow, nextCol)) {
                    return [nextRow, nextCol];
                }
                return null;
            };

            // Identify the start and end cells by iterating through the configuration grid
            config.forEach((column: any, row: number) => {
                column.forEach((cell: any, col: number) => {
                    if (cell.id === "START") startCell = [row, col]; // Identify start cell
                    if (cell.id === "END") endCell = [row, col]; // Identify end cell
                });
            });

            currentCell = startCell; // Start at the start cell

            // Loop until the current cell reaches the end cell
            while (currentCell[0] !== endCell[0] || currentCell[1] !== endCell[1]) {
                pastCells.push(currentCell); // Mark the current cell as visited

                // List of directions to check for neighboring cells
                const directions = ['left', 'right', 'top', 'bottom'];
                let newCell = null;

                // Check neighboring cells for "LOCATION" mode and add their ID to the warehouseMap
                directions.forEach(direction => {
                    const neighbor = getNeighborCell(currentCell[0], currentCell[1], direction);
                    if (neighbor && neighbor.mode === "LOCATION") {
                        warehouseMap.push(neighbor.id); // Add location ID to map
                    }
                });

                // Try to move to an adjacent "PATH" cell
                for (let direction of directions) {
                    newCell = updateCurrentCell(currentCell[0], currentCell[1], direction);
                    if (newCell) break; // If a valid path is found, move to that cell
                }

                // Check for the "END" cell in neighboring cells
                directions.forEach(direction => {
                    const neighbor = getNeighborCell(currentCell[0], currentCell[1], direction);
                    if (neighbor && neighbor.mode === "END") {
                        // Update newCell to match the direction of the "END" cell
                        newCell = [currentCell[0], currentCell[1] + (direction === 'right' ? 1 : direction === 'left' ? -1 : 0)];
                        newCell = [currentCell[0] + (direction === 'bottom' ? 1 : direction === 'top' ? -1 : 0), currentCell[1]];
                    }
                });

                // Break the loop if the newCell matches the endCell
                if (newCell && newCell[0] === endCell[0] && newCell[1] === endCell[1]) {
                    break; // End cell is reached
                }

                // If no valid path is found or the path hasn't changed, break the loop
                if (!newCell || (newCell[0] === currentCell[0] && newCell[1] === currentCell[1])) {
                    break; // No valid path found
                }

                // Move to the new cell
                currentCell = newCell;
            }
        }

        // Return the list of location IDs found along the path
        return warehouseMap;

    }

    async mapData() {
        const data: any = {}
        for (const item of this.data.items.filter((i: { picked: string; }) => !i.picked)) {
            if (!data[item.warehouse_location_uuid]) {
                data[item.warehouse_location_uuid] = {
                    name: ((await Utils.entity.get(item.warehouse_location_uuid, 'erp/warehouse_locations')).data[0].name),
                    pallets: {},
                    items: {}
                }
            }
            let target = data[item.warehouse_location_uuid].items
            if (item.warehouse_location_pallet_uuid) {
                if (!data[item.warehouse_location_uuid].pallets[item.warehouse_location_pallet_uuid]) {
                    data[item.warehouse_location_uuid].pallets[item.warehouse_location_pallet_uuid] = {
                        name: ((await Utils.entity.get(item.warehouse_location_pallet_uuid, 'erp/warehouse_location_pallets')).data[0].name),
                        items: {}
                    }
                }
            }
            if (!target[item.product_uuid]) {
                const p = (await Utils.entity.get(item.product_uuid, 'erp/products')).data[0]
                target[item.product_uuid] = {
                    name: p.name,
                    ean: p.ean,
                    productNumber: p.productNumber,
                    maps: []
                }
            }
            target[item.product_uuid].maps.push({
                uuid: item.uuid,
                mapId: item.warehouse_location_product_mapping_uuid,
                orderId: item.order_uuid,
                orderLineItemId: item.order_line_item_uuid,
                serial: item.serial,
                picked: item.picked
            })
        }
        const warehousePath = await this.generateWarehouseMap()
        console.log("warehousePath", warehousePath)
        warehousePath.forEach(key => {
            if (data.hasOwnProperty(key)) {
                this.pickData[key] = data[key];
            }
        });
    }

    createTable() {
        this.datatable = new DataTable('.datatables-users', {
            columns: [
                {data: 'palletName'},
                {data: 'productName'},
                {data: 'serial'},
            ],
            order: [[1, 'desc']],
            dom: 't',
            createdRow: (row: HTMLTableRowElement, data: IWMSPickTableRow, dataIndex: number) => {
                row.setAttribute("data-wms-id", data.wmsId);
                row.setAttribute("data-pallet-id", data.palletId);
                row.setAttribute("data-product-ean", data.ean);
                if (data.serial) {
                    row.setAttribute("data-serial", data.serial);
                }
            },
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info": `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: []
        });
    }


    async animateSwitch() {
        console.log("ANIM")
    }

    getCurrentLocationId() {
        for (const locationId of Object.keys(this.pickData)) {
            const location = this.pickData[locationId];
            const items = [Object.values(location.items), ...Object.values(location.pallets).map((p: any) => {
                return Object.values(p.items)
            })].flat();
            const unpickedItems = items.map((p: any) => {
                return p.maps
            }).flat().filter((p) => {
                return !p.picked
            })
            if (unpickedItems.length > 0) {
                return locationId;
            }
        }
        return null
    }

    async pickFinished() {
        await Utils.wms.generatePackages(this.generateOrderMap())
        await Utils.entity.upsert({
            uuid: this.data.uuid,
            status: "DONE",
        }, 'wms/transactions')
        document.location.href = `/${(window as any).currentLocale}/wms`
    }

    generateOrderMap() {
        let orderMap: IWMSOrderMap = {};
        (Object.values(this.pickData) as IWMSPickLocation[]).forEach((location: IWMSPickLocation) => {
            const items = [Object.values(location.items), ...Object.values(location.pallets).map(p => Object.values(p.items))].flat()
            const maps = items.map(i => i.maps).flat()
            items.forEach((item) => {
                item.maps.forEach((map) => {
                    if (!orderMap[map.orderId]) {
                        orderMap[map.orderId] = {}
                    }
                    if (!orderMap[map.orderId][map.orderLineItemId]) {
                        orderMap[map.orderId][map.orderLineItemId] = {
                            maps: [],
                            name: item.name,
                            productNumber: item.productNumber
                        }
                    }
                    orderMap[map.orderId][map.orderLineItemId].maps.push({
                        mapId: map.mapId,
                        serial: map.serial
                    })
                })
            })
        });

        return orderMap;
    }

    async continue() {
        const currentLocationId = this.getCurrentLocationId()
        if (!currentLocationId) {
            await this.pickFinished()
            return
        }
        if (currentLocationId !== this.currentLocationId && this.currentLocationId) {
            await this.animateSwitch();
        }
        this.currentLocationId = currentLocationId
        const currentLocation = this.pickData[this.currentLocationId];
        console.log("currentLocation", currentLocation)
        const currentCount = this.getItemCount(currentLocation);
        //Html Elements
        (document.querySelector("#wms_picking_current_location") as HTMLElement).innerHTML = currentLocation.name;
        (document.querySelector("#wms_picking_current_count") as HTMLElement).innerHTML = `${currentCount.picked}/${currentCount.total}`;
        if (this.datatable) {
            this.datatable.clear();
            this.datatable.rows.add(this.generateTableData(currentLocation));
            this.datatable.draw();
        }

    }

    generateTableData(location: IWMSPickLocation) {
        let a: IWMSPickTableRow[] = [];
        const generateItems = (parent: IWMSPickLocation | IWMSPickPallet, name: string, palletId: (string | null) = null) => {
            Object.keys(parent.items).forEach((productId) => {
                const product = parent.items[productId];
                product.maps.forEach((map: IWMSPickItemMap) => {
                    console.log(map)
                    if (!map.picked) {
                        a.push({
                            wmsId: map.uuid,
                            productId,
                            palletId,
                            palletName: name,
                            productName: `${product.name}[${product.ean}]`,
                            ean: product.ean,
                            serial: map.serial
                        })
                    }
                })
            })
        }
        Object.keys(location.pallets).forEach((palletId) => {
            const pallet = location.pallets[palletId];
            generateItems(pallet, pallet.name, palletId)
        })
        generateItems(location, "---", null)

        return a;
    }

    getItemCount(location: IWMSPickLocation) {
        let picked = 0;
        let total = 0;
        const getItems = (items: { [key: string]: IWMSPickListItem }) => {
            Object.keys(items).forEach((productId) => {
                const product = items[productId];
                product.maps.forEach((map: IWMSPickItemMap) => {
                    total++;
                    if (map.picked) {
                        picked++;
                    }
                })
            })
        };
        getItems(location.items)
        Object.keys(location.pallets).forEach((palletId) => {
            const pallet = location.pallets[palletId];
            getItems(pallet.items)
        })
        return {picked, total}
    }

    protected getEntityData(elem: any) {
        return WmsTransactionEntity.getEntityData(elem)
    }

    bindListeners() {
        document.addEventListener("click", (e) => {
            const target = e.target as null | HTMLElement
            if (target && target.id === 'wms_picking_cancel_action') {
                this.pickReset();
            }
        })
    }
}